import { useEffect } from 'react';
import { useFetch, useLoggedIn } from 'bv-hooks';
import { Spinner } from 'bv-components';
import { useParams } from 'react-router';
import { fetchFTPSportsJson } from './api';
import handlers from './handlers';
import ErrorModal from './error_modal';

const FtpSportLaunch = () => {
  const loggedIn = useLoggedIn();
  const { ftpGameId } = useParams();
  const [urlObject, urlLoading] = useFetch(() => fetchFTPSportsJson(ftpGameId),
    [loggedIn, ftpGameId]);

  useEffect(() => {
    const handleMessage = ({ origin, data }) => {
      if (urlObject?.url.startsWith(origin)) {
        handlers(data);
      }
    };

    window.addEventListener('message', handleMessage, false);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [urlObject]);

  if (urlLoading) return (<Spinner />);

  if (urlObject.status) {
    return (
      <ErrorModal errorCode={urlObject.status} />
    );
  }
  return (
    <iframe className="sbk-ftp-sport-launch" src={urlObject.url} />
  );
};

export default FtpSportLaunch;
