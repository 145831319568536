import addOutcomes from './add_outcomes';
import login from './login';

const handlers = {
  login,
  betslip: addOutcomes,
};

export default (data) => {
  try {
    handlers[data.action]?.(data);
  } catch (e) {
    // noop();
  }
};
